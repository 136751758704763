import React from 'react'
import Layout from '../components/Layout'
import {Link} from "gatsby";

const NotFoundPage = () => (
  <Layout showContactFlag={false}>
    <div className={"container my-5"}>
      <h1 className={"title is-1"}>NOT FOUND</h1>
      <p>The page you have tried to visit doesn't exist.</p>
      <Link to="/" className="navbar-item" title="Home">
        Click here to be redirected to the home page.
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
